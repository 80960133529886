// Generated by Avo VERSION 148.63.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "2GQRkd2H3Rnk4FV16NaK";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "RYaG82YbMWVADw2iOSai",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "5PhajbVijwhXVKIJtGMT",
          "se": (new Date()).toISOString(),
          "so": "OXY1Uf7nb",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "RYaG82YbMWVADw2iOSai",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "5PhajbVijwhXVKIJtGMT",
          "se": (new Date()).toISOString(),
          "so": "OXY1Uf7nb",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "5PhajbVijwhXVKIJtGMT",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const PupilActivityResourceTypes = {
  'STARTER_QUIZ': 'starter-quiz',
  'EXIT_QUIZ': 'exit-quiz',
  'WORKSHEET': 'worksheet',
  'VIDEO': 'video',
} as const;
export type PupilActivityResourceTypesType = typeof PupilActivityResourceTypes;
export type PupilActivityResourceTypesValueType = PupilActivityResourceTypesType[keyof PupilActivityResourceTypesType];

export const VideoLocation = {
  'WEBINAR': 'webinar',
  'MARKETING': 'marketing',
  'LESSON': 'lesson',
  'BLOG': 'blog',
  'PUPIL': 'pupil',
} as const;
export type VideoLocationType = typeof VideoLocation;
export type VideoLocationValueType = VideoLocationType[keyof VideoLocationType];

export const ResourceFileType = {
  'SHARE_TO_GOOGLE_DRIVE': 'share to google drive',
  'PDF': 'pdf',
  'DOCX': 'docx',
  'PPTX': 'pptx',
} as const;
export type ResourceFileTypeType = typeof ResourceFileType;
export type ResourceFileTypeValueType = ResourceFileTypeType[keyof ResourceFileTypeType];

export const Context = {
  'HOMEPAGE': 'homepage',
  'SEARCH': 'search',
} as const;
export type ContextType = typeof Context;
export type ContextValueType = ContextType[keyof ContextType];

export const OnwardIntent = {
  'VIEW_UNIT': 'view-unit',
  'VIEW_LESSON': 'view-lesson',
  'DOWNLOAD_LESSON_RESOURCES': 'download-lesson-resources',
} as const;
export type OnwardIntentType = typeof OnwardIntent;
export type OnwardIntentValueType = OnwardIntentType[keyof OnwardIntentType];

export const SchoolOption = {
  'HOMESCHOOL': 'Homeschool',
  'NOT_LISTED': 'Not listed',
  'SELECTED_SCHOOL': 'Selected school',
} as const;
export type SchoolOptionType = typeof SchoolOption;
export type SchoolOptionValueType = SchoolOptionType[keyof SchoolOptionType];

export const AnalyticsUseCase = {
  'PUPIL': 'Pupil',
  'TEACHER': 'Teacher',
} as const;
export type AnalyticsUseCaseType = typeof AnalyticsUseCase;
export type AnalyticsUseCaseValueType = AnalyticsUseCaseType[keyof AnalyticsUseCaseType];

export const DownloadResourceButtonName = {
  'ALL': 'all',
  'WORKSHEET': 'worksheet',
  'STARTER_QUIZ': 'starter quiz',
  'EXIT_QUIZ': 'exit quiz',
  'SLIDE_DECK': 'slide deck',
  'ADDITIONAL_MATERIAL': 'additional material',
} as const;
export type DownloadResourceButtonNameType = typeof DownloadResourceButtonName;
export type DownloadResourceButtonNameValueType = DownloadResourceButtonNameType[keyof DownloadResourceButtonNameType];

export const ResourceType = {
  'SLIDE_DECK': 'slide deck',
  'STARTER_QUIZ_QUESTIONS': 'starter quiz questions',
  'STARTER_QUIZ_ANSWERS': 'starter quiz answers',
  'EXIT_QUIZ_QUESTIONS': 'exit quiz questions',
  'EXIT_QUIZ_ANSWERS': 'exit quiz answers',
  'WORKSHEET_PDF': 'worksheet pdf',
  'WORKSHEET_PPTX': 'worksheet pptx',
  'ADDITIONAL_MATERIALS': 'additional materials',
  'CURRICULUM_DOCUMENT': 'curriculum document',
} as const;
export type ResourceTypeType = typeof ResourceType;
export type ResourceTypeValueType = ResourceTypeType[keyof ResourceTypeType];

export const PageName = {
  'SUBJECT_LISTING': 'Subject Listing',
  'UNIT_LISTING': 'Unit Listing',
  'HOMEPAGE': 'Homepage',
  'LESSON_LISTING': 'Lesson Listing',
  'LESSON': 'Lesson',
  'WEBINAR': 'Webinar',
  'BLOG': 'Blog',
  'PLAN_A_LESSON': 'Plan a Lesson',
  'DEVELOP_YOUR_CURRICULUM': 'Develop Your Curriculum',
  'SUPPORT_YOUR_TEAM': 'Support Your Team',
  'SEARCH': 'Search',
  'LESSON_DOWNLOAD': 'Lesson Download',
  'UNIT_DOWNLOAD': 'Unit Download',
  'BLOG_LISTING': 'Blog Listing',
  'WEBINAR_LISTING': 'Webinar Listing',
  'PROGRAMME_LISTING': 'Programme Listing',
  'CONTACT_US': 'Contact Us',
  'ABOUT_US_BOARD': 'About Us: Board',
  'ABOUT_US_WHO_WE_ARE': 'About Us: Who We Are',
  'ABOUT_US_LEADERSHIP': 'About Us: Leadership',
  'ABOUT_US_PARTNERS': 'About Us: Partners',
  'ABOUT_US_WORK_WITH_US': 'About Us: Work With Us',
  'LEGAL': 'Legal',
  'LANDING_PAGE': 'Landing Page',
  'EARLY_RELEASE_UNITS_PAGE': 'Early Release Units Page',
  'CURRICULUM_LANDING_PAGE': 'Curriculum Landing Page',
  'CURRICULUM_OVERVIEW': 'Curriculum Overview',
  'CURRICULUM_UNIT_SEQUENCE': 'Curriculum Unit Sequence',
  'CURRICULUM_DOWNLOADS': 'Curriculum Downloads',
  'LESSON_SHARE': 'Lesson Share',
  'CURRICULUM_PREVIOUS_DOWNLOADS': 'Curriculum Previous Downloads',
  'ONBOARDING_SCHOOL_SELECTION': 'Onboarding: School Selection',
  'ONBOARDING_ROLE_SELECTION': 'Onboarding: Role Selection',
  'ONBOARDING_WORK_IN_SCHOOL': 'Onboarding: Work In School',
  'ONBOARDING_USE_OF_OAK': 'Onboarding: Use Of Oak',
} as const;
export type PageNameType = typeof PageName;
export type PageNameValueType = PageNameType[keyof PageNameType];

export const Phase = {
  'PRIMARY': 'primary',
  'SECONDARY': 'secondary',
} as const;
export type PhaseType = typeof Phase;
export type PhaseValueType = PhaseType[keyof PhaseType];

export const PupilExperienceLessonSection = {
  'INTRO': 'intro',
  'VIDEO': 'video',
  'STARTER_QUIZ': 'starter-quiz',
  'EXIT_QUIZ': 'exit-quiz',
  'REVIEW': 'review',
} as const;
export type PupilExperienceLessonSectionType = typeof PupilExperienceLessonSection;
export type PupilExperienceLessonSectionValueType = PupilExperienceLessonSectionType[keyof PupilExperienceLessonSectionType];

export const FilterType = {
  'SUBJECT_FILTER': 'Subject filter',
  'KEY_STAGE_FILTER': 'Key stage filter',
  'CONTENT_TYPE_FILTER': 'Content type filter',
  'EXAM_BOARD_FILTER': 'Exam board filter',
} as const;
export type FilterTypeType = typeof FilterType;
export type FilterTypeValueType = FilterTypeType[keyof FilterTypeType];

export const ShareMedium = {
  'EMAIL': 'email',
  'COPY_LINK': 'copy-link',
  'MICROSOFT_TEAMS': 'microsoft-teams',
  'GOOGLE_CLASSROOM': 'google-classroom',
} as const;
export type ShareMediumType = typeof ShareMedium;
export type ShareMediumValueType = ShareMediumType[keyof ShareMediumType];

export const SearchSource = {
  'HOMEPAGE_SEARCH_BOX': 'homepage search box',
  'SEARCH_PAGE_SEARCH_BOX': 'search page search box',
  'HOMEPAGE_SEARCH_SUGGESTION': 'homepage search suggestion',
} as const;
export type SearchSourceType = typeof SearchSource;
export type SearchSourceValueType = SearchSourceType[keyof SearchSourceType];

export const NavigatedFrom = {
  'HEADER': 'header',
  'FOOTER': 'footer',
  'CARD': 'card',
  'MENU': 'menu',
} as const;
export type NavigatedFromType = typeof NavigatedFrom;
export type NavigatedFromValueType = NavigatedFromType[keyof NavigatedFromType];

export const KeyStageTitle = {
  'KEY_STAGE_1': 'Key stage 1',
  'KEY_STAGE_2': 'Key stage 2',
  'KEY_STAGE_3': 'Key stage 3',
  'KEY_STAGE_4': 'Key stage 4',
  'EARLY_YEARS_FOUNDATION_STAGE': 'Early Years Foundation stage',
  'SPECIALIST': 'Specialist',
  'THERAPIES': 'Therapies',
} as const;
export type KeyStageTitleType = typeof KeyStageTitle;
export type KeyStageTitleValueType = KeyStageTitleType[keyof KeyStageTitleType];

export const SearchResultType = {
  'UNIT': 'unit',
  'LESSON': 'lesson',
} as const;
export type SearchResultTypeType = typeof SearchResultType;
export type SearchResultTypeValueType = SearchResultTypeType[keyof SearchResultTypeType];

let PostHogEU: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  PostHogEUDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    PostHogEU = PostHogEUDestination;
    if (__AVO_ENV__ === 'prod') {
      PostHogEU && PostHogEU.make && PostHogEU.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      PostHogEU && PostHogEU.make && PostHogEU.make(__AVO_ENV__, null);
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for PostHogEU. Head to destination settings in Avo to set a staging key.");
      PostHogEU && PostHogEU.make && PostHogEU.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

function assertOrder(order: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("uLWinAUS-T", _label ? "Order" + ': ' + _label : "Order", order));
  messages = messages.concat(AvoAssert.assertMin("uLWinAUS-T", _label ? "Order" + ": " + _label : "Order", 0, order));
  return messages;
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

/**
 * Plan a Lesson Selected: Plan a lesson card is selected from the launch homepage
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6FJxOlnzBp/trigger/Hgd_u_6rr
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6FJxOlnzBp}
 */
export function planALessonSelected() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6FJxOlnzBp", "d813096b7695f541eaa49da02cc11793ba544ce60bc41c55042e58b8fbe1ee8a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Plan a Lesson Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6FJxOlnzBp", "Plan a Lesson Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Plan a Lesson Selected", eventProperties, "6FJxOlnzBp", "d813096b7695f541eaa49da02cc11793ba544ce60bc41c55042e58b8fbe1ee8a");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Plan a Lesson Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Newsletter Sign Up Completed: A visitor submits the form to subscribe to the newsletter
 *
 * When to trigger this event:
 * 1. User has completed the newsletter fields and pressed the Sign Up button
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/sLqKBjU-Wt/trigger/_MC_apwfp
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/sLqKBjU-Wt}
 */
export function newsletterSignUpCompleted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sLqKBjU-Wt", "085737f624ca5a6e8b1fe99fb1015c3fcef260da5335b1f6d4da68fd8b821f74", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Newsletter Sign Up Completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sLqKBjU-Wt", "Newsletter Sign Up Completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Newsletter Sign Up Completed", eventProperties, "sLqKBjU-Wt", "085737f624ca5a6e8b1fe99fb1015c3fcef260da5335b1f6d4da68fd8b821f74");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Newsletter Sign Up Completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ClassroomSelectedProperties {
  navigatedFrom: NavigatedFromValueType;
}
/**
 * Classroom Selected: Classroom app is selected from the Launch Homepage (card or header or menu or footer)
 *
 * When to trigger this event:
 * 1. Classroom app selected from homepage card
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/esgLdNSBsj/trigger/d46nqqMBY
 * 2. Classroom app selected from menu
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/esgLdNSBsj/trigger/M_vqoTLFQ
 *
 * @param properties the properties associatied with this event
 * @param properties.navigatedFrom: The location of the link used to navigate to the current page
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/esgLdNSBsj}
 */
export function classroomSelected(properties: ClassroomSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "p48bLbzXJ-", name: "Navigated From", value: properties.navigatedFrom});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "esgLdNSBsj", "8ff195436fbfc616e539a0c724a8fb07de6e6e8de5ffb3561669c43b9923d029", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Classroom Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("esgLdNSBsj", "Classroom Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Classroom Selected", eventProperties, "esgLdNSBsj", "8ff195436fbfc616e539a0c724a8fb07de6e6e8de5ffb3561669c43b9923d029");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Classroom Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TeacherHubSelectedProperties {
  navigatedFrom: NavigatedFromValueType;
}
/**
 * Teacher Hub Selected: Teacher Hub app is selected from the Launch Homepage (card or menu)
 *
 * When to trigger this event:
 * 1. Teacher Hub selected from homepage card
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/kp-dTd4WU3/trigger/8sYQASFRC
 * 2. Teacher Hub selected from menu
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/kp-dTd4WU3/trigger/_JhfvPPiP
 *
 * @param properties the properties associatied with this event
 * @param properties.navigatedFrom: The location of the link used to navigate to the current page
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/kp-dTd4WU3}
 */
export function teacherHubSelected(properties: TeacherHubSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "p48bLbzXJ-", name: "Navigated From", value: properties.navigatedFrom});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kp-dTd4WU3", "3284c81d636554f485aef25a3da0ecd34d604fd9090ea9409328d0c48fb99e95", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Teacher Hub Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kp-dTd4WU3", "Teacher Hub Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Teacher Hub Selected", eventProperties, "kp-dTd4WU3", "3284c81d636554f485aef25a3da0ecd34d604fd9090ea9409328d0c48fb99e95");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Teacher Hub Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Develop Your Curriculum Selected: Develop your curriculum card selected from launch homepage
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/pI9xLEeG6a/trigger/dRt9RWsfe
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/pI9xLEeG6a}
 */
export function developYourCurriculumSelected() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "pI9xLEeG6a", "a3481acb261e40b56a1e7bdf228810d449934bbf71dcbf404762de914b3269a9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Develop Your Curriculum Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("pI9xLEeG6a", "Develop Your Curriculum Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Develop Your Curriculum Selected", eventProperties, "pI9xLEeG6a", "a3481acb261e40b56a1e7bdf228810d449934bbf71dcbf404762de914b3269a9");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Develop Your Curriculum Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Support Your Team Selected: Support your team card selected from launch homepage
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/LqBk-euseD/trigger/F9AZuqUAA
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/LqBk-euseD}
 */
export function supportYourTeamSelected() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "LqBk-euseD", "18e7e3d7e51df91ebbe6302f12d59500b51a22454c6e28c9fa95d092e280d9b4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Support Your Team Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("LqBk-euseD", "Support Your Team Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Support Your Team Selected", eventProperties, "LqBk-euseD", "18e7e3d7e51df91ebbe6302f12d59500b51a22454c6e28c9fa95d092e280d9b4");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Support Your Team Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NotificationSelectedProperties {
  linkUrl: string;
  notificationHeadline: string;
}
/**
 * Notification Selected: Notification card from upper right corner of launch homepage is selected
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/M_zZRmq4zA/trigger/8JSZfjNwi
 *
 * @param properties the properties associatied with this event
 * @param properties.linkUrl: The href of a link
 * @param properties.notificationHeadline: The heading/link text in a notification card
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/M_zZRmq4zA}
 */
export function notificationSelected(
  properties: NotificationSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "cJ1c_F_Qih", name: "Link URL", value: properties.linkUrl});
  eventPropertiesArray.push({id: "QCncIcqjhM", name: "Notification Headline", value: properties.notificationHeadline});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "M_zZRmq4zA", "f07a125a314c437a86c016adca12309399e33f5fab2d85c56397c5f9ea9e43e3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Notification Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("M_zZRmq4zA", "Notification Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Notification Selected", eventProperties, "M_zZRmq4zA", "f07a125a314c437a86c016adca12309399e33f5fab2d85c56397c5f9ea9e43e3");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Notification Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * About Selected: About us is selected from the Launch homepage
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/Aj3imCEK7t}
 */
export function aboutSelected() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Aj3imCEK7t", "e9e86fc1929476c948b696d01c13c9a2a95807deb8d3b541b5ba8cbf94a96582", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("About Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Aj3imCEK7t", "About Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("About Selected", eventProperties, "Aj3imCEK7t", "e9e86fc1929476c948b696d01c13c9a2a95807deb8d3b541b5ba8cbf94a96582");
    }
    // destination PostHogEU
    PostHogEU.logEvent("About Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VideoStartedProperties {
  durationSeconds: number | null | undefined;
  isCaptioned: boolean;
  videoPlaybackId: string;
  videoTitle: string;
  timeElapsedSeconds: number;
  isMuted: boolean;
  videoLocation: VideoLocationValueType | null | undefined;
}
/**
 * Video Started: A video is played for the first time after a user lands on a page
 *
 * @param properties the properties associatied with this event
 * @param properties.durationSeconds: Video length in seconds
 * @param properties.isCaptioned: Indicates whether captions were enabled when the event was triggered
 * @param properties.videoPlaybackId: Playback Id of a mux video
 * @param properties.videoTitle: Title of video
 * @param properties.timeElapsedSeconds: Progress made through a video in seconds
 * @param properties.isMuted: Indicates whether video was muted when the event was triggered
 * @param properties.videoLocation: Where is the video on the site (eg. webinar, marketing, lesson, blog, etc)
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/G0I28K0B2f}
 */
export function videoStarted(properties: VideoStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.durationSeconds !== undefined && properties.durationSeconds !== null ?
    eventPropertiesArray.push({id: "NqtddGscIh", name: "Duration (Seconds)", value: properties.durationSeconds}) :
    eventPropertiesArray.push({id: "NqtddGscIh", name: "Duration (Seconds)", value: null});
  eventPropertiesArray.push({id: "xHdFKUwmsd", name: "Is Captioned", value: properties.isCaptioned});
  eventPropertiesArray.push({id: "ueSG_AEgt", name: "Video Playback Id", value: properties.videoPlaybackId});
  eventPropertiesArray.push({id: "S98ZxfGtRh", name: "Video Title", value: properties.videoTitle});
  eventPropertiesArray.push({id: "3a0mowzVyL", name: "Time Elapsed (Seconds)", value: properties.timeElapsedSeconds});
  eventPropertiesArray.push({id: "8AC7R8LYpO", name: "Is Muted", value: properties.isMuted});
  properties.videoLocation !== undefined && properties.videoLocation !== null ?
    eventPropertiesArray.push({id: "62ypa-_ys", name: "Video Location", value: properties.videoLocation}) :
    eventPropertiesArray.push({id: "62ypa-_ys", name: "Video Location", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "G0I28K0B2f", "c013df98ba39b93a964e8570ace140aea7d6d99e1bcd09add0f286b2d92165ea", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Video Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("G0I28K0B2f", "Video Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Video Started", eventProperties, "G0I28K0B2f", "c013df98ba39b93a964e8570ace140aea7d6d99e1bcd09add0f286b2d92165ea");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Video Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VideoPausedProperties {
  durationSeconds: number | null | undefined;
  isCaptioned: boolean;
  videoPlaybackId: string;
  videoTitle: string;
  timeElapsedSeconds: number;
  isMuted: boolean;
  videoLocation: VideoLocationValueType | null | undefined;
}
/**
 * Video Paused: A video is paused before it it finished
 *
 * @param properties the properties associatied with this event
 * @param properties.durationSeconds: Video length in seconds
 * @param properties.isCaptioned: Indicates whether captions were enabled when the event was triggered
 * @param properties.videoPlaybackId: Playback Id of a mux video
 * @param properties.videoTitle: Title of video
 * @param properties.timeElapsedSeconds: Progress made through a video in seconds
 * @param properties.isMuted: Indicates whether video was muted when the event was triggered
 * @param properties.videoLocation: Where is the video on the site (eg. webinar, marketing, lesson, blog, etc)
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/z91WauKeVB}
 */
export function videoPaused(properties: VideoPausedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.durationSeconds !== undefined && properties.durationSeconds !== null ?
    eventPropertiesArray.push({id: "NqtddGscIh", name: "Duration (Seconds)", value: properties.durationSeconds}) :
    eventPropertiesArray.push({id: "NqtddGscIh", name: "Duration (Seconds)", value: null});
  eventPropertiesArray.push({id: "xHdFKUwmsd", name: "Is Captioned", value: properties.isCaptioned});
  eventPropertiesArray.push({id: "ueSG_AEgt", name: "Video Playback Id", value: properties.videoPlaybackId});
  eventPropertiesArray.push({id: "S98ZxfGtRh", name: "Video Title", value: properties.videoTitle});
  eventPropertiesArray.push({id: "3a0mowzVyL", name: "Time Elapsed (Seconds)", value: properties.timeElapsedSeconds});
  eventPropertiesArray.push({id: "8AC7R8LYpO", name: "Is Muted", value: properties.isMuted});
  properties.videoLocation !== undefined && properties.videoLocation !== null ?
    eventPropertiesArray.push({id: "62ypa-_ys", name: "Video Location", value: properties.videoLocation}) :
    eventPropertiesArray.push({id: "62ypa-_ys", name: "Video Location", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "z91WauKeVB", "fb2a077f4f082acefc3e236c58f47cd89694c4e681b4d77165b4bbfec0376b4c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Video Paused", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("z91WauKeVB", "Video Paused", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Video Paused", eventProperties, "z91WauKeVB", "fb2a077f4f082acefc3e236c58f47cd89694c4e681b4d77165b4bbfec0376b4c");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Video Paused", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VideoPlayedProperties {
  durationSeconds: number | null | undefined;
  isCaptioned: boolean;
  videoPlaybackId: string;
  videoTitle: string;
  timeElapsedSeconds: number;
  isMuted: boolean;
  videoLocation: VideoLocationValueType | null | undefined;
}
/**
 * Video Played: A video is played again after being paused
 *
 * @param properties the properties associatied with this event
 * @param properties.durationSeconds: Video length in seconds
 * @param properties.isCaptioned: Indicates whether captions were enabled when the event was triggered
 * @param properties.videoPlaybackId: Playback Id of a mux video
 * @param properties.videoTitle: Title of video
 * @param properties.timeElapsedSeconds: Progress made through a video in seconds
 * @param properties.isMuted: Indicates whether video was muted when the event was triggered
 * @param properties.videoLocation: Where is the video on the site (eg. webinar, marketing, lesson, blog, etc)
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/r4DFADUHFh}
 */
export function videoPlayed(properties: VideoPlayedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.durationSeconds !== undefined && properties.durationSeconds !== null ?
    eventPropertiesArray.push({id: "NqtddGscIh", name: "Duration (Seconds)", value: properties.durationSeconds}) :
    eventPropertiesArray.push({id: "NqtddGscIh", name: "Duration (Seconds)", value: null});
  eventPropertiesArray.push({id: "xHdFKUwmsd", name: "Is Captioned", value: properties.isCaptioned});
  eventPropertiesArray.push({id: "ueSG_AEgt", name: "Video Playback Id", value: properties.videoPlaybackId});
  eventPropertiesArray.push({id: "S98ZxfGtRh", name: "Video Title", value: properties.videoTitle});
  eventPropertiesArray.push({id: "3a0mowzVyL", name: "Time Elapsed (Seconds)", value: properties.timeElapsedSeconds});
  eventPropertiesArray.push({id: "8AC7R8LYpO", name: "Is Muted", value: properties.isMuted});
  properties.videoLocation !== undefined && properties.videoLocation !== null ?
    eventPropertiesArray.push({id: "62ypa-_ys", name: "Video Location", value: properties.videoLocation}) :
    eventPropertiesArray.push({id: "62ypa-_ys", name: "Video Location", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "r4DFADUHFh", "f69f9c23cb4d083662ec3204e467484c449526448c420d245898ad73e498f0de", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Video Played", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("r4DFADUHFh", "Video Played", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Video Played", eventProperties, "r4DFADUHFh", "f69f9c23cb4d083662ec3204e467484c449526448c420d245898ad73e498f0de");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Video Played", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VideoFinishedProperties {
  durationSeconds: number | null | undefined;
  isCaptioned: boolean;
  videoPlaybackId: string;
  videoTitle: string;
  timeElapsedSeconds: number;
  isMuted: boolean;
  videoLocation: VideoLocationValueType | null | undefined;
}
/**
 * Video Finished: The end of a video is reached
 *
 * @param properties the properties associatied with this event
 * @param properties.durationSeconds: Video length in seconds
 * @param properties.isCaptioned: Indicates whether captions were enabled when the event was triggered
 * @param properties.videoPlaybackId: Playback Id of a mux video
 * @param properties.videoTitle: Title of video
 * @param properties.timeElapsedSeconds: Progress made through a video in seconds
 * @param properties.isMuted: Indicates whether video was muted when the event was triggered
 * @param properties.videoLocation: Where is the video on the site (eg. webinar, marketing, lesson, blog, etc)
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/NP9klWkaki}
 */
export function videoFinished(properties: VideoFinishedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.durationSeconds !== undefined && properties.durationSeconds !== null ?
    eventPropertiesArray.push({id: "NqtddGscIh", name: "Duration (Seconds)", value: properties.durationSeconds}) :
    eventPropertiesArray.push({id: "NqtddGscIh", name: "Duration (Seconds)", value: null});
  eventPropertiesArray.push({id: "xHdFKUwmsd", name: "Is Captioned", value: properties.isCaptioned});
  eventPropertiesArray.push({id: "ueSG_AEgt", name: "Video Playback Id", value: properties.videoPlaybackId});
  eventPropertiesArray.push({id: "S98ZxfGtRh", name: "Video Title", value: properties.videoTitle});
  eventPropertiesArray.push({id: "3a0mowzVyL", name: "Time Elapsed (Seconds)", value: properties.timeElapsedSeconds});
  eventPropertiesArray.push({id: "8AC7R8LYpO", name: "Is Muted", value: properties.isMuted});
  properties.videoLocation !== undefined && properties.videoLocation !== null ?
    eventPropertiesArray.push({id: "62ypa-_ys", name: "Video Location", value: properties.videoLocation}) :
    eventPropertiesArray.push({id: "62ypa-_ys", name: "Video Location", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NP9klWkaki", "cca1e8596a28b4f16879f127992256c28e0db2b9e77856c669f7af6bd1d818ff", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Video Finished", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NP9klWkaki", "Video Finished", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Video Finished", eventProperties, "NP9klWkaki", "cca1e8596a28b4f16879f127992256c28e0db2b9e77856c669f7af6bd1d818ff");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Video Finished", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonResourcesDownloadedProperties {
  keyStageTitle: KeyStageTitleValueType | null | undefined;
  keyStageSlug: string | null | undefined;
  unitName: string | null | undefined;
  unitSlug: string | null | undefined;
  subjectTitle: string | null | undefined;
  subjectSlug: string | null | undefined;
  lessonName: string;
  lessonSlug: string;
  schoolUrn: number;
  schoolName: string;
  schoolOption: SchoolOptionValueType;
  resourceType: ResourceTypeValueType[];
  analyticsUseCase: AnalyticsUseCaseValueType;
  emailSupplied: boolean;
  onwardContent: string[];
}
/**
 * Lesson Resources Downloaded: A user downloaded one or more resources for a lesson
 *
 * When to trigger this event:
 * 1. The download .zip button is clicked on the lesson download page and submission passes validation checks
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/k9ZQJai7ws/trigger/sAqordxVG
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.schoolUrn: School URN linked to GIAS attributes
 * @param properties.schoolName: Name of the school chosen from the school picker
 * @param properties.schoolOption: The option the user has selected as their school (selected school, homeschool or not listed)
 * @param properties.resourceType: The lesson resources a teacher selected for download.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.emailSupplied: A user has signed up for updates on the downloads page
 * @param properties.onwardContent: A list of slugs representing content (e.g. lessons) that are placed to allow a user journey to 'continue' onward.
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/k9ZQJai7ws}
 */
export function lessonResourcesDownloaded(
  properties: LessonResourcesDownloadedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.keyStageTitle !== undefined && properties.keyStageTitle !== null ?
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle}) :
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: null});
  properties.keyStageSlug !== undefined && properties.keyStageSlug !== null ?
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug}) :
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: null});
  properties.unitName !== undefined && properties.unitName !== null ?
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName}) :
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: null});
  properties.unitSlug !== undefined && properties.unitSlug !== null ?
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug}) :
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: null});
  properties.subjectTitle !== undefined && properties.subjectTitle !== null ?
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle}) :
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: null});
  properties.subjectSlug !== undefined && properties.subjectSlug !== null ?
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug}) :
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: null});
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "G1iO4wRoL5", name: "School URN", value: properties.schoolUrn});
  eventPropertiesArray.push({id: "54PPZ-gkS", name: "School Name", value: properties.schoolName});
  eventPropertiesArray.push({id: "CrzKvLBC3", name: "School Option", value: properties.schoolOption});
  eventPropertiesArray.push({id: "H_kc7WuVNP", name: "Resource Type", value: properties.resourceType});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "3wxlGffcE", name: "Email Supplied", value: properties.emailSupplied});
  eventPropertiesArray.push({id: "5IrIuSjxs", name: "Onward Content", value: properties.onwardContent});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "k9ZQJai7ws", "82b2b77318ae1ec8297d18c549e3cf30c9addeabb4c4e16d9ab5afd8a216132d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Resources Downloaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("k9ZQJai7ws", "Lesson Resources Downloaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Resources Downloaded", eventProperties, "k9ZQJai7ws", "82b2b77318ae1ec8297d18c549e3cf30c9addeabb4c4e16d9ab5afd8a216132d");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Resources Downloaded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface KeyStageSelectedProperties {
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  navigatedFrom: NavigatedFromValueType;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Key Stage Selected: A user chooses the key stage they want to view.
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/7ONADbQPez/trigger/bkIRr49k7
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.navigatedFrom: The location of the link used to navigate to the current page
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/7ONADbQPez}
 */
export function keyStageSelected(properties: KeyStageSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "p48bLbzXJ-", name: "Navigated From", value: properties.navigatedFrom});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7ONADbQPez", "864ff27cecc8d9dd85d466ec6a9ed34e4f434aa8b1b51dcba4dee407b0035bee", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Key Stage Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7ONADbQPez", "Key Stage Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Key Stage Selected", eventProperties, "7ONADbQPez", "864ff27cecc8d9dd85d466ec6a9ed34e4f434aa8b1b51dcba4dee407b0035bee");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Key Stage Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SubjectSelectedProperties {
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Subject Selected: A teacher chooses the subject they want to view.
 *
 * When to trigger this event:
 * 1. One of the yellow subject cards is selected
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/ufkcMq0HT4/trigger/Pl87P0XPk
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/ufkcMq0HT4}
 */
export function subjectSelected(properties: SubjectSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ufkcMq0HT4", "fd1adbbe4ea28151b20a16adaf01a6109ee34e2a627e4ccad18ba84038ac4194", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Subject Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ufkcMq0HT4", "Subject Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Subject Selected", eventProperties, "ufkcMq0HT4", "fd1adbbe4ea28151b20a16adaf01a6109ee34e2a627e4ccad18ba84038ac4194");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Subject Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface UnitSelectedProperties {
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  unitName: string;
  unitSlug: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Unit Selected: A teacher chooses the unit they want to view.
 *
 * When to trigger this event:
 * 1. A unit card is selected from the unit listing page
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/Me4ouIgPxh/trigger/t8lbfIsGe
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/Me4ouIgPxh}
 */
export function unitSelected(properties: UnitSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Me4ouIgPxh", "f310b01f765a1eb49e02fee76b5abf4ddbb225bb71d2f064b38b9a7dca8fe1a2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Unit Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Me4ouIgPxh", "Unit Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Unit Selected", eventProperties, "Me4ouIgPxh", "f310b01f765a1eb49e02fee76b5abf4ddbb225bb71d2f064b38b9a7dca8fe1a2");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Unit Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonSelectedProperties {
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  unitName: string;
  unitSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  lessonName: string;
  lessonSlug: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Lesson Selected: A teacher chooses a lesson they want to view
 *
 * When to trigger this event:
 * 1. Lesson is selected from listing page
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/iUvld79OSb/trigger/TnGi5eBdB
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/iUvld79OSb}
 */
export function lessonSelected(properties: LessonSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iUvld79OSb", "4c193bf70e60bc86002bf0b7c9094bbde0c19c6e62c5c3a4b2e28033bf5c86c6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iUvld79OSb", "Lesson Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Selected", eventProperties, "iUvld79OSb", "4c193bf70e60bc86002bf0b7c9094bbde0c19c6e62c5c3a4b2e28033bf5c86c6");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface YearGroupSelectedProperties {
  yearGroupName: string;
  yearGroupSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Year Group Selected: A student chooses the year group they want to view.
 *
 * When to trigger this event:
 * 1. An example of year group being selected from within the curriculum visualiser tool
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6DwKZEuYUk/trigger/Y36tMwp1l
 *
 * @param properties the properties associatied with this event
 * @param properties.yearGroupName: Name of the current year group.
 * @param properties.yearGroupSlug: Human-readable unique ID of the current year group.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6DwKZEuYUk}
 */
export function yearGroupSelected(properties: YearGroupSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "mIpfmyUTSY", name: "Year Group Name", value: properties.yearGroupName});
  eventPropertiesArray.push({id: "ySTg1Sz9in", name: "Year Group Slug", value: properties.yearGroupSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6DwKZEuYUk", "48cee6d7b7a4856ca32cb98a2026de24fca8527930317811f28e6352aa396334", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Year Group Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6DwKZEuYUk", "Year Group Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Year Group Selected", eventProperties, "6DwKZEuYUk", "48cee6d7b7a4856ca32cb98a2026de24fca8527930317811f28e6352aa396334");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Year Group Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface WebinarPageViewedProperties {
  webinarTitle: string;
  webinarCategory: string;
  videoAvailable: boolean;
}
/**
 * Webinar Page Viewed: A user loads a webinar page
 *
 * @param properties the properties associatied with this event
 * @param properties.webinarTitle: Title of the webinar
 * @param properties.webinarCategory: Category of the webinar
 * @param properties.videoAvailable: Watchable video is available on page (eg. not 'upcoming' or 'processing' status
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/-9FHudlATb}
 */
export function webinarPageViewed(properties: WebinarPageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "EEuyiIwAD", name: "Webinar Title", value: properties.webinarTitle});
  eventPropertiesArray.push({id: "MbEsLei6Z", name: "Webinar Category", value: properties.webinarCategory});
  eventPropertiesArray.push({id: "StT0U3M69", name: "Video Available", value: properties.videoAvailable});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-9FHudlATb", "ebf8f74a217a39aefe9153d249e65dfd00927a93e0811de345e3edf20dc2ed39", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Webinar Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-9FHudlATb", "Webinar Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Webinar Page Viewed", eventProperties, "-9FHudlATb", "ebf8f74a217a39aefe9153d249e65dfd00927a93e0811de345e3edf20dc2ed39");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Webinar Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Help Centre Selected: Visit help centre button is clicked on the homepage
 *
 * When to trigger this event:
 * 1. Visit help centre button clicked
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/Ls0Ou1w7qK/trigger/RAhU-otN8
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/Ls0Ou1w7qK}
 */
export function helpCentreSelected() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Ls0Ou1w7qK", "56af691cf1453c2843da2e84c18c26aba60176dbd8f88253c13780a664026e16", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Help Centre Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Ls0Ou1w7qK", "Help Centre Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Help Centre Selected", eventProperties, "Ls0Ou1w7qK", "56af691cf1453c2843da2e84c18c26aba60176dbd8f88253c13780a664026e16");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Help Centre Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LearningThemeSelectedProperties {
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
  learningThemeName: string;
}
/**
 * Learning Theme Selected: A learning theme filter is applied on a unit listing page
 *
 * When to trigger this event:
 * 1. A learning theme is selected on a unit listing page
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/Vg3AmapucP/trigger/ssM2tCEE3
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.learningThemeName: Name of the learning theme used to group units
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/Vg3AmapucP}
 */
export function learningThemeSelected(
  properties: LearningThemeSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "-t7ga1V-X", name: "Learning Theme Name", value: properties.learningThemeName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Vg3AmapucP", "82b96557783201e4e3ce6446cfc719bc5ee4250482e38b8e0dda60217ed06738", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Learning Theme Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Vg3AmapucP", "Learning Theme Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Learning Theme Selected", eventProperties, "Vg3AmapucP", "82b96557783201e4e3ce6446cfc719bc5ee4250482e38b8e0dda60217ed06738");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Learning Theme Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TierSelectedProperties {
  subjectTitle: string;
  subjectSlug: string;
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  tierName: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Tier Selected: A user makes a tier choice to show a grouping of units
 *
 * When to trigger this event:
 * 1. On the unit page a Learning tier is selected

NB. This currently only impacts KS4 Maths, but is expected to also impact KS4 Sciences once the new content is introduced
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6lyU_rQz42/trigger/HHKzuwVa4
 *
 * @param properties the properties associatied with this event
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.tierName: Name of the learning tier
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6lyU_rQz42}
 */
export function tierSelected(properties: TierSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "IFuPjLaNs", name: "Tier Name", value: properties.tierName});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6lyU_rQz42", "1aca701b6766f9662543587018ff5f6686b12eae9f6f9aa9d80265beaa5f3922", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Tier Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6lyU_rQz42", "Tier Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Tier Selected", eventProperties, "6lyU_rQz42", "1aca701b6766f9662543587018ff5f6686b12eae9f6f9aa9d80265beaa5f3922");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Tier Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PageviewProperties {
  linkUrl: string;
  analyticsUseCase: AnalyticsUseCaseValueType | null | undefined;
  pageName: PageNameValueType;
}
/**
 * $pageview: A user views the page (DO NOT change the name of this event, as it is a special name in Posthog)
 *
 * @param properties the properties associatied with this event
 * @param properties.linkUrl: The href of a link
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.pageName: The main pages of a user journey eg. subject listing, unit listing, etc
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/iYVEEwNT0q}
 */
export function pageview(properties: PageviewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "cJ1c_F_Qih", name: "Link URL", value: properties.linkUrl});
  properties.analyticsUseCase !== undefined && properties.analyticsUseCase !== null ?
    eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase}) :
    eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: null});
  eventPropertiesArray.push({id: "LgWcfE0FOm", name: "Page Name", value: properties.pageName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iYVEEwNT0q", "d29e7cdf55be9c4e870bbeff9003c221ea8ae12417b0b27f8535bdead88e8f15", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("$pageview", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iYVEEwNT0q", "$pageview", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("$pageview", eventProperties, "iYVEEwNT0q", "d29e7cdf55be9c4e870bbeff9003c221ea8ae12417b0b27f8535bdead88e8f15");
    }
    // destination PostHogEU
    PostHogEU.logEvent("$pageview", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ResourceContainerExpandedProperties {
  analyticsUseCase: AnalyticsUseCaseValueType;
  pageName: PageNameValueType;
  containerTitle: string;
}
/**
 * Resource Container Expanded: A resource container is expanded to expose the lesson resource content
 *
 * When to trigger this event:
 * 1. A container chevron is expanded to allow a view of the resource.

NB. resource could be attached at a lesson or unit level.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/B-ti6Ajp8/trigger/57LUx8uOY
 *
 * @param properties the properties associatied with this event
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.pageName: The main pages of a user journey eg. subject listing, unit listing, etc
 * @param properties.containerTitle: The title of the container bar.
eg. Slide deck, Worksheet, Video, Starter quiz, Exit quiz, Unit quiz, Transcript, Lesson overview
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/B-ti6Ajp8}
 */
export function resourceContainerExpanded(
  properties: ResourceContainerExpandedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "LgWcfE0FOm", name: "Page Name", value: properties.pageName});
  eventPropertiesArray.push({id: "o3aX6Ur5U", name: "Container Title", value: properties.containerTitle});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "B-ti6Ajp8", "1e6faa042271b6261d37f87fb29758409e05bfc714ffaee72ab92f66677ace53", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Resource Container Expanded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("B-ti6Ajp8", "Resource Container Expanded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Resource Container Expanded", eventProperties, "B-ti6Ajp8", "1e6faa042271b6261d37f87fb29758409e05bfc714ffaee72ab92f66677ace53");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Resource Container Expanded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CurriculumMapDownloadedProperties {
  subjectTitle: string;
  subjectSlug: string;
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
  pageName: PageNameValueType;
}
/**
 * Curriculum Map Downloaded: A user downloads a curriculum map on a unit listing or a lesson listing page
 *
 * When to trigger this event:
 * 1. User clicks the Curriculum download (PDF) button on a unit listing page
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/W9R2vOSii_/trigger/l30pZnEW3
 * 2. User clicks the Curriculum download (PDF) button on a lesson listing page
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/W9R2vOSii_/trigger/0LJTo1tA3
 *
 * @param properties the properties associatied with this event
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.pageName: The main pages of a user journey eg. subject listing, unit listing, etc
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/W9R2vOSii_}
 */
export function curriculumMapDownloaded(
  properties: CurriculumMapDownloadedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "LgWcfE0FOm", name: "Page Name", value: properties.pageName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "W9R2vOSii_", "c4a239d62a337d400a87f284bf70f1726135460176a70a9ae073862778155220", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Curriculum Map Downloaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("W9R2vOSii_", "Curriculum Map Downloaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Curriculum Map Downloaded", eventProperties, "W9R2vOSii_", "c4a239d62a337d400a87f284bf70f1726135460176a70a9ae073862778155220");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Curriculum Map Downloaded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface DownloadResourceButtonClickedProperties {
  keyStageTitle: KeyStageTitleValueType | null | undefined;
  keyStageSlug: string | null | undefined;
  subjectTitle: string | null | undefined;
  subjectSlug: string | null | undefined;
  unitName: string | null | undefined;
  unitSlug: string | null | undefined;
  lessonName: string;
  lessonSlug: string;
  downloadResourceButtonName: DownloadResourceButtonNameValueType;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Download Resource Button Clicked: A download button is clicked on the lesson overview page
 *
 * When to trigger this event:
 * 1. Download all resources button is clicked on the lesson overview page
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/0n50tfMg2N/trigger/w2ACuk2F3
 * 2. An individual resource download button on an expandable container is clicked (eg. slide deck, starter quiz, worksheet, etc)
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/0n50tfMg2N/trigger/5PBz6j66G
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.downloadResourceButtonName: The name of the button to download the resource (all, or individual resources)
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/0n50tfMg2N}
 */
export function downloadResourceButtonClicked(
  properties: DownloadResourceButtonClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.keyStageTitle !== undefined && properties.keyStageTitle !== null ?
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle}) :
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: null});
  properties.keyStageSlug !== undefined && properties.keyStageSlug !== null ?
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug}) :
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: null});
  properties.subjectTitle !== undefined && properties.subjectTitle !== null ?
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle}) :
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: null});
  properties.subjectSlug !== undefined && properties.subjectSlug !== null ?
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug}) :
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: null});
  properties.unitName !== undefined && properties.unitName !== null ?
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName}) :
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: null});
  properties.unitSlug !== undefined && properties.unitSlug !== null ?
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug}) :
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: null});
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "GeeELBAAq", name: "Download Resource Button Name", value: properties.downloadResourceButtonName});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0n50tfMg2N", "2b1c2dcc3037f7781eea84cab65f0145eec9aecd4f3f26dd191312364c64be1d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Download Resource Button Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0n50tfMg2N", "Download Resource Button Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Download Resource Button Clicked", eventProperties, "0n50tfMg2N", "2b1c2dcc3037f7781eea84cab65f0145eec9aecd4f3f26dd191312364c64be1d");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Download Resource Button Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SearchAttemptedProperties {
  searchTerm: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
  pageName: PageNameValueType;
  searchFilterOptionSelected: string[];
  searchSource: SearchSourceValueType;
  context: ContextValueType;
}
/**
 * Search Attempted: A user enters a search term (eg. lesson or unit topic, keyword), submits the search from the homepage or search page itself
 *
 * When to trigger this event:
 * 1. A user initiates a search from the homepage by
entering the search term and clicking "Go"
entering the search term and pressing ENTER on the keyboard
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/I_dSzYI2PB/trigger/GRZ05_zLD
 * 2. A user initiates a search from the search page (eg. they refine their search term or try looking for something else) by entering the search term and
clicking "Go", or
pressing ENTER
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/I_dSzYI2PB/trigger/SG8O6HFoP
 * 3. A search is initiated from the homepage by clicking on one of the search suggestion option beneath the search box
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/I_dSzYI2PB/trigger/5gULgsDoY
 *
 * @param properties the properties associatied with this event
 * @param properties.searchTerm: What the user has types in the search box
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.pageName: The main pages of a user journey eg. subject listing, unit listing, etc
 * @param properties.searchFilterOptionSelected: The specific search filter option that has been selected (eg. 'ks2,ks3')
NB. ensure filters are sent in a consistent order
 * @param properties.searchSource: The location of the search box/entry point.
eg. homepage search box, search page search box, hamburger menu search box (future)
 * @param properties.context: Relates to the application or view from which a action was performed
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/I_dSzYI2PB}
 */
export function searchAttempted(properties: SearchAttemptedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "hHufJiP_N", name: "Search Term", value: properties.searchTerm});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "LgWcfE0FOm", name: "Page Name", value: properties.pageName});
  eventPropertiesArray.push({id: "psOVldb0Z", name: "Search Filter Option Selected", value: properties.searchFilterOptionSelected});
  eventPropertiesArray.push({id: "cAhJwc7aw", name: "Search Source", value: properties.searchSource});
  eventPropertiesArray.push({id: "9BNUma5en", name: "Context", value: properties.context});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "I_dSzYI2PB", "79ed1fd316f3ffd24983cfe142b872f225d07f98f6dde3c67af538b1b42dd71e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Search Attempted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("I_dSzYI2PB", "Search Attempted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Search Attempted", eventProperties, "I_dSzYI2PB", "79ed1fd316f3ffd24983cfe142b872f225d07f98f6dde3c67af538b1b42dd71e");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Search Attempted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SearchResultOpenedProperties {
  keyStageTitle: KeyStageTitleValueType | null | undefined;
  keyStageSlug: string | null | undefined;
  subjectTitle: string | null | undefined;
  subjectSlug: string | null | undefined;
  unitName: string | null | undefined;
  unitSlug: string | null | undefined;
  lessonName: string | null | undefined;
  lessonSlug: string | null | undefined;
  analyticsUseCase: AnalyticsUseCaseValueType;
  searchRank: number;
  searchFilterOptionSelected: string[];
  searchResultCount: number;
  searchResultType: SearchResultTypeValueType;
  context: ContextValueType;
}
/**
 * Search Result Opened: User clicks on a search result from the listing
 *
 * When to trigger this event:
 * 1. A search result card (could be unit or lesson) is clicked
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/_TD-gtSgIj/trigger/ZezXTZ0ri
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.searchRank: The position of the clicked search result
 * @param properties.searchFilterOptionSelected: The specific search filter option that has been selected (eg. 'ks2,ks3')
NB. ensure filters are sent in a consistent order
 * @param properties.searchResultCount: total number of search results returned
 * @param properties.searchResultType: The type of result returned (eg. unit or lesson)
 * @param properties.context: Relates to the application or view from which a action was performed
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/_TD-gtSgIj}
 */
export function searchResultOpened(properties: SearchResultOpenedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.keyStageTitle !== undefined && properties.keyStageTitle !== null ?
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle}) :
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: null});
  properties.keyStageSlug !== undefined && properties.keyStageSlug !== null ?
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug}) :
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: null});
  properties.subjectTitle !== undefined && properties.subjectTitle !== null ?
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle}) :
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: null});
  properties.subjectSlug !== undefined && properties.subjectSlug !== null ?
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug}) :
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: null});
  properties.unitName !== undefined && properties.unitName !== null ?
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName}) :
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: null});
  properties.unitSlug !== undefined && properties.unitSlug !== null ?
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug}) :
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: null});
  properties.lessonName !== undefined && properties.lessonName !== null ?
    eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName}) :
    eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: null});
  properties.lessonSlug !== undefined && properties.lessonSlug !== null ?
    eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug}) :
    eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: null});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "Gvu_40yW7", name: "Search Rank", value: properties.searchRank});
  eventPropertiesArray.push({id: "psOVldb0Z", name: "Search Filter Option Selected", value: properties.searchFilterOptionSelected});
  eventPropertiesArray.push({id: "ssKpAufWU", name: "Search Result Count", value: properties.searchResultCount});
  eventPropertiesArray.push({id: "weaA0iE9I", name: "Search Result Type", value: properties.searchResultType});
  eventPropertiesArray.push({id: "9BNUma5en", name: "Context", value: properties.context});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_TD-gtSgIj", "ca97fbeed78b8f3dbb7d876362d8e359282a81fe498a92cad5f59e92421216d2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Search Result Opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_TD-gtSgIj", "Search Result Opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Search Result Opened", eventProperties, "_TD-gtSgIj", "ca97fbeed78b8f3dbb7d876362d8e359282a81fe498a92cad5f59e92421216d2");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Search Result Opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SearchResultsDisplayedProperties {
  searchFilterOptionSelected: string[];
  searchResultCount: number;
  analyticsUseCase: AnalyticsUseCaseValueType;
  searchResultsLoadTime: number;
  context: ContextValueType;
}
/**
 * Search Results Displayed: When a search term has been submitted and results are displayed
 *
 * When to trigger this event:
 * 1. The results are displayed on the search page
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/QI7bIsW3os/trigger/IQb7tOYbR
 *
 * @param properties the properties associatied with this event
 * @param properties.searchFilterOptionSelected: The specific search filter option that has been selected (eg. 'ks2,ks3')
NB. ensure filters are sent in a consistent order
 * @param properties.searchResultCount: total number of search results returned
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.searchResultsLoadTime: Amount of time taken to fetch and load the search results
 * @param properties.context: Relates to the application or view from which a action was performed
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/QI7bIsW3os}
 */
export function searchResultsDisplayed(
  properties: SearchResultsDisplayedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "psOVldb0Z", name: "Search Filter Option Selected", value: properties.searchFilterOptionSelected});
  eventPropertiesArray.push({id: "ssKpAufWU", name: "Search Result Count", value: properties.searchResultCount});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "uKpVoe2qJ", name: "Search Results Load Time", value: properties.searchResultsLoadTime});
  eventPropertiesArray.push({id: "9BNUma5en", name: "Context", value: properties.context});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QI7bIsW3os", "ef63c92377972c38180fdc001b9215d50ce96f1cfd9bd13b29515a762883a89f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Search Results Displayed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QI7bIsW3os", "Search Results Displayed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Search Results Displayed", eventProperties, "QI7bIsW3os", "ef63c92377972c38180fdc001b9215d50ce96f1cfd9bd13b29515a762883a89f");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Search Results Displayed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SearchJourneyInitiatedProperties {
  searchSource: SearchSourceValueType;
  context: ContextValueType;
}
/**
 * Search Journey Initiated: The search box is selected
 *
 * When to trigger this event:
 * 1. A user types in the search bar on the homepage (first letter is typed).
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/rYxJBK8Kpm/trigger/aeEheVqLW
 * 2. A user types in the search bar on the search page (first letter is typed)
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/rYxJBK8Kpm/trigger/5OM1jFoWf
 *
 * @param properties the properties associatied with this event
 * @param properties.searchSource: The location of the search box/entry point.
eg. homepage search box, search page search box, hamburger menu search box (future)
 * @param properties.context: Relates to the application or view from which a action was performed
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/rYxJBK8Kpm}
 */
export function searchJourneyInitiated(
  properties: SearchJourneyInitiatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "cAhJwc7aw", name: "Search Source", value: properties.searchSource});
  eventPropertiesArray.push({id: "9BNUma5en", name: "Context", value: properties.context});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "rYxJBK8Kpm", "006d2562e0107acacb267f0facf567771921d7c7151c3853af18c57c38437ce3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Search Journey Initiated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("rYxJBK8Kpm", "Search Journey Initiated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Search Journey Initiated", eventProperties, "rYxJBK8Kpm", "006d2562e0107acacb267f0facf567771921d7c7151c3853af18c57c38437ce3");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Search Journey Initiated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CurriculumVisualiserAccessedProperties {
  subjectTitle: string;
  subjectSlug: string;
  phase: PhaseValueType;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Curriculum Visualiser Accessed: A user opening the curriculum visualiser tool for use having select a subject and phase.
 *
 * When to trigger this event:
 * 1. A user has clicked view after successfully completing the "Subject" and "School phase" fields.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/_ImUDkApb7/trigger/R19ayisHj
 * 2. Illustration of same component with would trigger this event but this time from the curriculum visualiser itself where this event could also be triggered
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/_ImUDkApb7/trigger/LFOFUdkpr
 *
 * @param properties the properties associatied with this event
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.phase: School phase related to key stage and age of audience
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/_ImUDkApb7}
 */
export function curriculumVisualiserAccessed(
  properties: CurriculumVisualiserAccessedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "SsbNnb9vD", name: "Phase", value: properties.phase});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_ImUDkApb7", "ec79d5156b9e2e67783e8738fc4f7e8a1a8864b12ed65b1d675166003e318674", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Curriculum Visualiser Accessed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_ImUDkApb7", "Curriculum Visualiser Accessed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Curriculum Visualiser Accessed", eventProperties, "_ImUDkApb7", "ec79d5156b9e2e67783e8738fc4f7e8a1a8864b12ed65b1d675166003e318674");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Curriculum Visualiser Accessed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CurriculumThreadHighlightedProperties {
  subjectTitle: string;
  subjectSlug: string;
  threadTitle: string;
  threadSlug: string;
  phase: PhaseValueType;
  order: number;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Curriculum Thread Highlighted: A unit thread is highlighted using the curriculum visualiser
 *
 * When to trigger this event:
 * 1. item div is clicked whilst curriculum visualiser is open
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/sR1S6swOBk/trigger/0ajfpuq__
 *
 * @param properties the properties associatied with this event
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.threadTitle: The title (in plain English including spaces) of a thread corresponding to a sequence of lessons across units
 * @param properties.threadSlug: The human readable identifier (n-kebab-case) for a sequence of lessons
 * @param properties.phase: School phase related to key stage and age of audience
 * @param properties.order: The position of the item within a list
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/sR1S6swOBk}
 */
export function curriculumThreadHighlighted(
  properties: CurriculumThreadHighlightedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "5vqHiL5Sh", name: "Thread Title", value: properties.threadTitle});
  eventPropertiesArray.push({id: "E1mlACg_OT", name: "Thread Slug", value: properties.threadSlug});
  eventPropertiesArray.push({id: "SsbNnb9vD", name: "Phase", value: properties.phase});
  eventPropertiesArray.push({id: "uLWinAUS-T", name: "Order", value: properties.order});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertOrder(properties.order));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sR1S6swOBk", "c9bff1f666c945345e39a1e240c0c834acef12e1059765f5e130972a60cee6d0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Curriculum Thread Highlighted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sR1S6swOBk", "Curriculum Thread Highlighted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Curriculum Thread Highlighted': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Curriculum Thread Highlighted", eventProperties, "sR1S6swOBk", "c9bff1f666c945345e39a1e240c0c834acef12e1059765f5e130972a60cee6d0");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Curriculum Thread Highlighted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface UnitInformationViewedProperties {
  unitName: string;
  unitSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  yearGroupName: string;
  yearGroupSlug: string;
  unitHighlighted: boolean;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Unit Information Viewed: A user of the curriculum visualiser views information relating to a highlighted (or non-highlighted) unit
 *
 * When to trigger this event:
 * 1. Unit info div is selected on div corresponding to a particular unit (in this case one that is highlighted a resulted of the selected thread)
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/OnjKTo8kYs/trigger/I6HnzeUNk
 *
 * @param properties the properties associatied with this event
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.yearGroupName: Name of the current year group.
 * @param properties.yearGroupSlug: Human-readable unique ID of the current year group.
 * @param properties.unitHighlighted: A boolean describing whether a given unit is highlighted when unit information is viewed
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/OnjKTo8kYs}
 */
export function unitInformationViewed(
  properties: UnitInformationViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "mIpfmyUTSY", name: "Year Group Name", value: properties.yearGroupName});
  eventPropertiesArray.push({id: "ySTg1Sz9in", name: "Year Group Slug", value: properties.yearGroupSlug});
  eventPropertiesArray.push({id: "WIkFSM1sX", name: "Unit Highlighted", value: properties.unitHighlighted});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "OnjKTo8kYs", "8e3d585247764895b07e99b44fe627b2287abc26af8a815b36219687644dc798", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Unit Information Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("OnjKTo8kYs", "Unit Information Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Unit Information Viewed", eventProperties, "OnjKTo8kYs", "8e3d585247764895b07e99b44fe627b2287abc26af8a815b36219687644dc798");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Unit Information Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OnwardContentSelectedProperties {
  unitName: string;
  unitSlug: string;
  lessonName: string;
  lessonSlug: string;
  onwardIntent: OnwardIntentValueType;
}
/**
 * Onward Content Selected: Triggered when user continues their journey following the presentation of potential content of interest. In case of download or lesson view, lesson fields are populated. In case of unit being viewed unit fields are populated.
 *
 * When to trigger this event:
 * 1. Onward Content presentation screen following the download of a resource. Any of the links highlighted below would trigger this event.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/H9jrWEk8wy/trigger/t_FkWxgTH
 *
 * @param properties the properties associatied with this event
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.onwardIntent: Describes the intent of the action when a user journey continues following the presentation of relevant content
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/H9jrWEk8wy}
 */
export function onwardContentSelected(
  properties: OnwardContentSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "CC9YnUSeo", name: "Onward Intent", value: properties.onwardIntent});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "H9jrWEk8wy", "59e105a0f035a38f40e13cad256d0edb51d7695c088943471a4a45e06c95a26e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Onward Content Selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("H9jrWEk8wy", "Onward Content Selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Onward Content Selected", eventProperties, "H9jrWEk8wy", "59e105a0f035a38f40e13cad256d0edb51d7695c088943471a4a45e06c95a26e");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Onward Content Selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonSharedProperties {
  lessonName: string;
  lessonSlug: string;
  schoolUrn: number;
  schoolName: string;
  schoolOption: SchoolOptionValueType;
  shareMedium: ShareMediumValueType;
  pupilActivityResourceTypes: PupilActivityResourceTypesValueType[];
  emailSupplied: boolean;
}
/**
 * Lesson Shared: The event of a teacher sharing a selection of resources relating to a lesson with pupils.
 *
 * When to trigger this event:
 * 1. One of the share options is selected
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/RnJu4EY4bA/trigger/lvtGVFDJo
 *
 * @param properties the properties associatied with this event
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.schoolUrn: School URN linked to GIAS attributes
 * @param properties.schoolName: Name of the school chosen from the school picker
 * @param properties.schoolOption: The option the user has selected as their school (selected school, homeschool or not listed)
 * @param properties.shareMedium: The medium through which a link to a resource is shared
 * @param properties.pupilActivityResourceTypes: The type of pupil facing activity(yes) selected (e.g. when a lesson is shared with pupils)
 * @param properties.emailSupplied: A user has signed up for updates on the downloads page
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/RnJu4EY4bA}
 */
export function lessonShared(properties: LessonSharedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "G1iO4wRoL5", name: "School URN", value: properties.schoolUrn});
  eventPropertiesArray.push({id: "54PPZ-gkS", name: "School Name", value: properties.schoolName});
  eventPropertiesArray.push({id: "CrzKvLBC3", name: "School Option", value: properties.schoolOption});
  eventPropertiesArray.push({id: "aMDyFQBYq", name: "Share Medium", value: properties.shareMedium});
  eventPropertiesArray.push({id: "4Vptzwuw7", name: "Pupil Activity Resource Types", value: properties.pupilActivityResourceTypes});
  eventPropertiesArray.push({id: "3wxlGffcE", name: "Email Supplied", value: properties.emailSupplied});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "RnJu4EY4bA", "5f91b959f509eaed2e1ee38842f75e6df5e1c4f075383693567b3256004d72c9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Shared", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("RnJu4EY4bA", "Lesson Shared", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Shared", eventProperties, "RnJu4EY4bA", "5f91b959f509eaed2e1ee38842f75e6df5e1c4f075383693567b3256004d72c9");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Shared", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonShareStartedProperties {
  lessonName: string;
  lessonSlug: string;
  unitName: string;
  unitSlug: string;
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  subjectTitle: string;
  subjectSlug: string;
}
/**
 * Lesson Share Started: A user beings the process of sharing a lesson with their pupils. This event shows they are interested in sharing something but happens prior to a user generating the required link that would suggest the sharing has taken place.
 *
 * When to trigger this event:
 * 1. Share activities with pupils button clicked at top of the page
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/PS8tcnGrN2/trigger/Lsaaeanis
 * 2. Share activities with pupils button clicked at the resource level

NB. There is no sharing of slide decks at this time
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/PS8tcnGrN2/trigger/QMYuEgDaG
 *
 * @param properties the properties associatied with this event
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/PS8tcnGrN2}
 */
export function lessonShareStarted(properties: LessonShareStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "PS8tcnGrN2", "2a12d01ac125612833eb2f5bcbeb0fe8668a390e975f012bc211a862dda92769", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Share Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("PS8tcnGrN2", "Lesson Share Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Share Started", eventProperties, "PS8tcnGrN2", "2a12d01ac125612833eb2f5bcbeb0fe8668a390e975f012bc211a862dda92769");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Share Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SearchRefinedProperties {
  context: ContextValueType;
  filterType: FilterTypeValueType;
  filterValue: string;
  searchResultCount: number;
}
/**
 * Search Refined: The user updates a filter on the search results page
 *
 * @param properties the properties associatied with this event
 * @param properties.context: Relates to the application or view from which a action was performed
 * @param properties.filterType: Describes the dimension the filter is designed to control such as subject, key stage, or resource type
 * @param properties.filterValue: The value added to the filter (e.g. 'English' or 'AQA')
 * @param properties.searchResultCount: total number of search results returned
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/XpQ27vPNH4}
 */
export function searchRefined(properties: SearchRefinedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "9BNUma5en", name: "Context", value: properties.context});
  eventPropertiesArray.push({id: "XPABjlx_F", name: "Filter Type", value: properties.filterType});
  eventPropertiesArray.push({id: "v3Ne3qUXs", name: "Filter Value", value: properties.filterValue});
  eventPropertiesArray.push({id: "ssKpAufWU", name: "Search Result Count", value: properties.searchResultCount});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "XpQ27vPNH4", "09022dde4eefe816ca1d8939493d87a2be75400dd73053519205d3f995893513", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Search Refined", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("XpQ27vPNH4", "Search Refined", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Search Refined", eventProperties, "XpQ27vPNH4", "09022dde4eefe816ca1d8939493d87a2be75400dd73053519205d3f995893513");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Search Refined", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SearchResultExpandedProperties {
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  unitName: string;
  unitSlug: string;
  lessonName: string;
  lessonSlug: string;
  context: ContextValueType;
  searchRank: number;
  searchFilterOptionSelected: string[];
  searchResultCount: number;
  searchResultType: SearchResultTypeValueType;
}
/**
 * Search Result Expanded: The User expands a search result to display optionality around Exam Board or difficulty
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.context: Relates to the application or view from which a action was performed
 * @param properties.searchRank: The position of the clicked search result
 * @param properties.searchFilterOptionSelected: The specific search filter option that has been selected (eg. 'ks2,ks3')
NB. ensure filters are sent in a consistent order
 * @param properties.searchResultCount: total number of search results returned
 * @param properties.searchResultType: The type of result returned (eg. unit or lesson)
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/eP2lOQQj8C}
 */
export function searchResultExpanded(
  properties: SearchResultExpandedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "9BNUma5en", name: "Context", value: properties.context});
  eventPropertiesArray.push({id: "Gvu_40yW7", name: "Search Rank", value: properties.searchRank});
  eventPropertiesArray.push({id: "psOVldb0Z", name: "Search Filter Option Selected", value: properties.searchFilterOptionSelected});
  eventPropertiesArray.push({id: "ssKpAufWU", name: "Search Result Count", value: properties.searchResultCount});
  eventPropertiesArray.push({id: "weaA0iE9I", name: "Search Result Type", value: properties.searchResultType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "eP2lOQQj8C", "9cec746b0608e66d67b5db59b2664631e652dc53b1e3d428f1ca3f6ce6749658", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Search Result Expanded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("eP2lOQQj8C", "Search Result Expanded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Search Result Expanded", eventProperties, "eP2lOQQj8C", "9cec746b0608e66d67b5db59b2664631e652dc53b1e3d428f1ca3f6ce6749658");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Search Result Expanded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonCompletedProperties {
  lessonName: string;
  lessonSlug: string;
  unitName: string | null | undefined;
  unitSlug: string | null | undefined;
  subjectTitle: string | null | undefined;
  subjectSlug: string | null | undefined;
  keyStageTitle: KeyStageTitleValueType | null | undefined;
  keyStageSlug: string | null | undefined;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Lesson Completed: When a pupil completes all the activities in a lesson during the pupil experience.
 *
 * @param properties the properties associatied with this event
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/Hy-6QNrYKU}
 */
export function lessonCompleted(properties: LessonCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  properties.unitName !== undefined && properties.unitName !== null ?
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName}) :
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: null});
  properties.unitSlug !== undefined && properties.unitSlug !== null ?
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug}) :
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: null});
  properties.subjectTitle !== undefined && properties.subjectTitle !== null ?
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle}) :
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: null});
  properties.subjectSlug !== undefined && properties.subjectSlug !== null ?
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug}) :
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: null});
  properties.keyStageTitle !== undefined && properties.keyStageTitle !== null ?
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle}) :
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: null});
  properties.keyStageSlug !== undefined && properties.keyStageSlug !== null ?
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug}) :
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: null});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Hy-6QNrYKU", "b38faeadf5bc5b1a6ce56262f24b11f9f74edc9b9bcde1bca99878abc322cf02", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Hy-6QNrYKU", "Lesson Completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Completed", eventProperties, "Hy-6QNrYKU", "b38faeadf5bc5b1a6ce56262f24b11f9f74edc9b9bcde1bca99878abc322cf02");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonSectionCompletedProperties {
  lessonName: string;
  lessonSlug: string;
  unitName: string | null | undefined;
  unitSlug: string | null | undefined;
  subjectTitle: string | null | undefined;
  subjectSlug: string | null | undefined;
  keyStageTitle: KeyStageTitleValueType | null | undefined;
  keyStageSlug: string | null | undefined;
  pupilQuizGrade: number | null | undefined;
  pupilQuizNumQuestions: number | null | undefined;
  pupilWorksheetAvailable: boolean | null | undefined;
  pupilWorksheetDownloaded: boolean | null | undefined;
  pupilVideoPlayed: boolean | null | undefined;
  pupilVideoDurationSeconds: number | null | undefined;
  pupilVideoTimeEllapsedSeconds: number | null | undefined;
  analyticsUseCase: AnalyticsUseCaseValueType;
  pupilExperienceLessonSection: PupilExperienceLessonSectionValueType;
}
/**
 * Lesson Section Completed: When a pupil completes a section of a lesson in the pupil experience
 *
 * @param properties the properties associatied with this event
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.pupilQuizGrade: no description
 * @param properties.pupilQuizNumQuestions: no description
 * @param properties.pupilWorksheetAvailable: no description
 * @param properties.pupilWorksheetDownloaded: no description
 * @param properties.pupilVideoPlayed: True if the play button has been pressed at all during the session
 * @param properties.pupilVideoDurationSeconds: no description
 * @param properties.pupilVideoTimeEllapsedSeconds: no description
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.pupilExperienceLessonSection: All Oak pupil lessons have a set of sections which can be interacted with. This property tracks which section a particular action (e.g. starting, completing, abandoning) a particular event relates to.
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/sBllxa-nuz}
 */
export function lessonSectionCompleted(
  properties: LessonSectionCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  properties.unitName !== undefined && properties.unitName !== null ?
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName}) :
    eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: null});
  properties.unitSlug !== undefined && properties.unitSlug !== null ?
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug}) :
    eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: null});
  properties.subjectTitle !== undefined && properties.subjectTitle !== null ?
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle}) :
    eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: null});
  properties.subjectSlug !== undefined && properties.subjectSlug !== null ?
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug}) :
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: null});
  properties.keyStageTitle !== undefined && properties.keyStageTitle !== null ?
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle}) :
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: null});
  properties.keyStageSlug !== undefined && properties.keyStageSlug !== null ?
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug}) :
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: null});
  properties.pupilQuizGrade !== undefined && properties.pupilQuizGrade !== null ?
    eventPropertiesArray.push({id: "12xmn1uQF", name: "Pupil Quiz Grade", value: properties.pupilQuizGrade}) :
    eventPropertiesArray.push({id: "12xmn1uQF", name: "Pupil Quiz Grade", value: null});
  properties.pupilQuizNumQuestions !== undefined && properties.pupilQuizNumQuestions !== null ?
    eventPropertiesArray.push({id: "y28SwSKr9", name: "Pupil Quiz Num Questions", value: properties.pupilQuizNumQuestions}) :
    eventPropertiesArray.push({id: "y28SwSKr9", name: "Pupil Quiz Num Questions", value: null});
  properties.pupilWorksheetAvailable !== undefined && properties.pupilWorksheetAvailable !== null ?
    eventPropertiesArray.push({id: "rNM1I8vY_", name: "Pupil Worksheet Available", value: properties.pupilWorksheetAvailable}) :
    eventPropertiesArray.push({id: "rNM1I8vY_", name: "Pupil Worksheet Available", value: null});
  properties.pupilWorksheetDownloaded !== undefined && properties.pupilWorksheetDownloaded !== null ?
    eventPropertiesArray.push({id: "AbY2tLMm3", name: "Pupil Worksheet Downloaded", value: properties.pupilWorksheetDownloaded}) :
    eventPropertiesArray.push({id: "AbY2tLMm3", name: "Pupil Worksheet Downloaded", value: null});
  properties.pupilVideoPlayed !== undefined && properties.pupilVideoPlayed !== null ?
    eventPropertiesArray.push({id: "hmpQPI6sI", name: "Pupil Video Played", value: properties.pupilVideoPlayed}) :
    eventPropertiesArray.push({id: "hmpQPI6sI", name: "Pupil Video Played", value: null});
  properties.pupilVideoDurationSeconds !== undefined && properties.pupilVideoDurationSeconds !== null ?
    eventPropertiesArray.push({id: "4Ei7ce0ZP", name: "Pupil Video Duration (Seconds)", value: properties.pupilVideoDurationSeconds}) :
    eventPropertiesArray.push({id: "4Ei7ce0ZP", name: "Pupil Video Duration (Seconds)", value: null});
  properties.pupilVideoTimeEllapsedSeconds !== undefined && properties.pupilVideoTimeEllapsedSeconds !== null ?
    eventPropertiesArray.push({id: "cOQ8T6zw3", name: "Pupil Video Time Ellapsed (Seconds)", value: properties.pupilVideoTimeEllapsedSeconds}) :
    eventPropertiesArray.push({id: "cOQ8T6zw3", name: "Pupil Video Time Ellapsed (Seconds)", value: null});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "Vv6ZE-oYpa", name: "Pupil Experience Lesson Section", value: properties.pupilExperienceLessonSection});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sBllxa-nuz", "6b8f963fb09f6481a8e77975e8ed5a230da8336ec1c2ca10d0b8da0b12cea8d1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Section Completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sBllxa-nuz", "Lesson Section Completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Section Completed", eventProperties, "sBllxa-nuz", "6b8f963fb09f6481a8e77975e8ed5a230da8336ec1c2ca10d0b8da0b12cea8d1");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Section Completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonStartedProperties {
  keyStageTitle: KeyStageTitleValueType | null | undefined;
  keyStageSlug: string;
  unitName: string;
  unitSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  lessonName: string;
  lessonSlug: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
}
/**
 * Lesson Started: A student starts a new lesson, or a teacher views a lesson as a student.
 *
 * @param properties the properties associatied with this event
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/9zz1OCsT2}
 */
export function lessonStarted(properties: LessonStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.keyStageTitle !== undefined && properties.keyStageTitle !== null ?
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle}) :
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: null});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9zz1OCsT2", "acd63cef6ef5c62967559403635a68bdbefbd146798496c0dcdd3048bd0101d1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9zz1OCsT2", "Lesson Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Started", eventProperties, "9zz1OCsT2", "acd63cef6ef5c62967559403635a68bdbefbd146798496c0dcdd3048bd0101d1");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonSectionStartedProperties {
  lessonName: string;
  lessonSlug: string;
  unitName: string;
  unitSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  keyStageTitle: KeyStageTitleValueType | null | undefined;
  keyStageSlug: string;
  analyticsUseCase: AnalyticsUseCaseValueType;
  pupilExperienceLessonSection: PupilExperienceLessonSectionValueType;
}
/**
 * Lesson Section Started: When a pupil completes a section of a lesson in the pupil experience
 *
 * @param properties the properties associatied with this event
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.pupilExperienceLessonSection: All Oak pupil lessons have a set of sections which can be interacted with. This property tracks which section a particular action (e.g. starting, completing, abandoning) a particular event relates to.
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/8LfOc60dl}
 */
export function lessonSectionStarted(
  properties: LessonSectionStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  properties.keyStageTitle !== undefined && properties.keyStageTitle !== null ?
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle}) :
    eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: null});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "Vv6ZE-oYpa", name: "Pupil Experience Lesson Section", value: properties.pupilExperienceLessonSection});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8LfOc60dl", "b00acff72914d1a960d839ef55bf807cfc64563b94f50e807c634a7ce891f30b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Section Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8LfOc60dl", "Lesson Section Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Section Started", eventProperties, "8LfOc60dl", "b00acff72914d1a960d839ef55bf807cfc64563b94f50e807c634a7ce891f30b");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Section Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CurriculumResourcesDownloadedProperties {
  analyticsUseCase: AnalyticsUseCaseValueType;
  emailSupplied: boolean;
  resourceType: ResourceTypeValueType[];
  schoolOption: SchoolOptionValueType;
  schoolUrn: number;
  schoolName: string;
  subjectTitle: string;
  keyStageTitle: KeyStageTitleValueType;
}
/**
 * Curriculum Resources Downloaded: Curriculum Resources Downloaded: A user downloaded one or more resources for a curriculum.
 *
 * When to trigger this event:
 * 1. The download .zip button is clicked on a curriculum download page and submission passes validation checks
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/L7-HOgqfOB/trigger/sRDz6cOhH
 *
 * @param properties the properties associatied with this event
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.emailSupplied: A user has signed up for updates on the downloads page
 * @param properties.resourceType: The lesson resources a teacher selected for download.
 * @param properties.schoolOption: The option the user has selected as their school (selected school, homeschool or not listed)
 * @param properties.schoolUrn: School URN linked to GIAS attributes
 * @param properties.schoolName: Name of the school chosen from the school picker
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.keyStageTitle: Title of the current key stage.
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/L7-HOgqfOB}
 */
export function curriculumResourcesDownloaded(
  properties: CurriculumResourcesDownloadedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "3wxlGffcE", name: "Email Supplied", value: properties.emailSupplied});
  eventPropertiesArray.push({id: "H_kc7WuVNP", name: "Resource Type", value: properties.resourceType});
  eventPropertiesArray.push({id: "CrzKvLBC3", name: "School Option", value: properties.schoolOption});
  eventPropertiesArray.push({id: "G1iO4wRoL5", name: "School URN", value: properties.schoolUrn});
  eventPropertiesArray.push({id: "54PPZ-gkS", name: "School Name", value: properties.schoolName});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "L7-HOgqfOB", "0ec0808bac2add2137ae9ebf57c1a1b3d889e14061a3ac4a2ea8e21296a20998", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Curriculum Resources Downloaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("L7-HOgqfOB", "Curriculum Resources Downloaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Curriculum Resources Downloaded", eventProperties, "L7-HOgqfOB", "0ec0808bac2add2137ae9ebf57c1a1b3d889e14061a3ac4a2ea8e21296a20998");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Curriculum Resources Downloaded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CurriculumResourcesDownloadedCurriculumDocumentProperties {
  analyticsUseCase: AnalyticsUseCaseValueType;
  emailSupplied: boolean;
  schoolOption: SchoolOptionValueType;
  schoolUrn: number;
  schoolName: string;
  subjectTitle: string;
  childSubjectName: string | null | undefined;
  resourceFileType: ResourceFileTypeValueType;
  examBoardSlug: string | null | undefined;
  subjectSlug: string;
  tierName: string | null | undefined;
  childSubjectSlug: string | null | undefined;
  phase: PhaseValueType;
}
/**
 * Curriculum Resources Downloaded: Curriculum Resources Downloaded: A user downloaded one or more resources for a curriculum., variant description: A user downloads a Curriculum Document
 *
 * @param properties the properties associatied with this event
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.emailSupplied: A user has signed up for updates on the downloads page
 * @param properties.schoolOption: The option the user has selected as their school (selected school, homeschool or not listed)
 * @param properties.schoolUrn: School URN linked to GIAS attributes
 * @param properties.schoolName: Name of the school chosen from the school picker
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.childSubjectName: Name of the Child Subject Associated with the event
 * @param properties.resourceFileType: The file type of the resource related to the event
 * @param properties.examBoardSlug: Slug of the Exam Board associated with the event.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.tierName: Name of the learning tier
 * @param properties.childSubjectSlug: Slug of Child Subject associated with the event
 * @param properties.phase: School phase related to key stage and age of audience
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/L7-HOgqfOB.bCK4k0eAk}
 */
export function curriculumResourcesDownloadedCurriculumDocument(
  properties: CurriculumResourcesDownloadedCurriculumDocumentProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "3wxlGffcE", name: "Email Supplied", value: properties.emailSupplied});
  eventPropertiesArray.push({id: "CrzKvLBC3", name: "School Option", value: properties.schoolOption});
  eventPropertiesArray.push({id: "G1iO4wRoL5", name: "School URN", value: properties.schoolUrn});
  eventPropertiesArray.push({id: "54PPZ-gkS", name: "School Name", value: properties.schoolName});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  properties.childSubjectName !== undefined && properties.childSubjectName !== null ?
    eventPropertiesArray.push({id: "5QAyZtsHD", name: "Child Subject Name", value: properties.childSubjectName}) :
    eventPropertiesArray.push({id: "5QAyZtsHD", name: "Child Subject Name", value: null});
  eventPropertiesArray.push({id: "6_1GeCgXu", name: "Resource File Type", value: properties.resourceFileType});
  properties.examBoardSlug !== undefined && properties.examBoardSlug !== null ?
    eventPropertiesArray.push({id: "79pkUNtB2", name: "Exam Board Slug", value: properties.examBoardSlug}) :
    eventPropertiesArray.push({id: "79pkUNtB2", name: "Exam Board Slug", value: null});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  properties.tierName !== undefined && properties.tierName !== null ?
    eventPropertiesArray.push({id: "IFuPjLaNs", name: "Tier Name", value: properties.tierName}) :
    eventPropertiesArray.push({id: "IFuPjLaNs", name: "Tier Name", value: null});
  properties.childSubjectSlug !== undefined && properties.childSubjectSlug !== null ?
    eventPropertiesArray.push({id: "S4dKDojap", name: "Child Subject Slug", value: properties.childSubjectSlug}) :
    eventPropertiesArray.push({id: "S4dKDojap", name: "Child Subject Slug", value: null});
  eventPropertiesArray.push({id: "SsbNnb9vD", name: "Phase", value: properties.phase});
  eventPropertiesArray.push({id: "H_kc7WuVNP", name: "Resource Type", value: "curriculum document"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "L7-HOgqfOB.bCK4k0eAk", "db05d7680e99953c0d943b0f157822c52e9ab1c0bb7ebc4cacf8a318df4c7129", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Curriculum Resources Downloaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("L7-HOgqfOB.bCK4k0eAk", "Curriculum Resources Downloaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Curriculum Resources Downloaded", eventProperties, "L7-HOgqfOB.bCK4k0eAk", "db05d7680e99953c0d943b0f157822c52e9ab1c0bb7ebc4cacf8a318df4c7129");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Curriculum Resources Downloaded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonSectionAbandonedProperties {
  lessonName: string;
  lessonSlug: string;
  unitName: string;
  unitSlug: string;
  subjectTitle: string;
  subjectSlug: string;
  keyStageTitle: KeyStageTitleValueType;
  keyStageSlug: string;
  pupilQuizGrade: number | null | undefined;
  pupilQuizNumQuestions: number | null | undefined;
  pupilWorksheetAvailable: boolean | null | undefined;
  pupilWorksheetDownloaded: boolean | null | undefined;
  pupilVideoPlayed: boolean | null | undefined;
  pupilVideoDurationSeconds: number | null | undefined;
  pupilVideoTimeEllapsedSeconds: number | null | undefined;
  analyticsUseCase: AnalyticsUseCaseValueType;
  pupilExperienceLessonSection: PupilExperienceLessonSectionValueType;
}
/**
 * Lesson Section Abandoned: When a pupil leaves a lesson section before completing it
 *
 * @param properties the properties associatied with this event
 * @param properties.lessonName: Name of the current lesson.
 * @param properties.lessonSlug: Human-readable unique ID of the current lesson.
 * @param properties.unitName: Title of the current unit.
 * @param properties.unitSlug: Human-readable unique ID of the current unit.
 * @param properties.subjectTitle: Title of the current subject.
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageTitle: Title of the current key stage.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.pupilQuizGrade: no description
 * @param properties.pupilQuizNumQuestions: no description
 * @param properties.pupilWorksheetAvailable: no description
 * @param properties.pupilWorksheetDownloaded: no description
 * @param properties.pupilVideoPlayed: True if the play button has been pressed at all during the session
 * @param properties.pupilVideoDurationSeconds: no description
 * @param properties.pupilVideoTimeEllapsedSeconds: no description
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers
 * @param properties.pupilExperienceLessonSection: All Oak pupil lessons have a set of sections which can be interacted with. This property tracks which section a particular action (e.g. starting, completing, abandoning) a particular event relates to.
 *
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/lucA3x3s4}
 */
export function lessonSectionAbandoned(
  properties: LessonSectionAbandonedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "vbCKXJ6xRQ", name: "Lesson Name", value: properties.lessonName});
  eventPropertiesArray.push({id: "1FiHL77eSX", name: "Lesson Slug", value: properties.lessonSlug});
  eventPropertiesArray.push({id: "YfsvSpyEEd", name: "Unit Name", value: properties.unitName});
  eventPropertiesArray.push({id: "r4GW5No741", name: "Unit Slug", value: properties.unitSlug});
  eventPropertiesArray.push({id: "-MoOjO43sV", name: "Subject Title", value: properties.subjectTitle});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "qeEZpYqVhK", name: "Key Stage Title", value: properties.keyStageTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  properties.pupilQuizGrade !== undefined && properties.pupilQuizGrade !== null ?
    eventPropertiesArray.push({id: "12xmn1uQF", name: "Pupil Quiz Grade", value: properties.pupilQuizGrade}) :
    eventPropertiesArray.push({id: "12xmn1uQF", name: "Pupil Quiz Grade", value: null});
  properties.pupilQuizNumQuestions !== undefined && properties.pupilQuizNumQuestions !== null ?
    eventPropertiesArray.push({id: "y28SwSKr9", name: "Pupil Quiz Num Questions", value: properties.pupilQuizNumQuestions}) :
    eventPropertiesArray.push({id: "y28SwSKr9", name: "Pupil Quiz Num Questions", value: null});
  properties.pupilWorksheetAvailable !== undefined && properties.pupilWorksheetAvailable !== null ?
    eventPropertiesArray.push({id: "rNM1I8vY_", name: "Pupil Worksheet Available", value: properties.pupilWorksheetAvailable}) :
    eventPropertiesArray.push({id: "rNM1I8vY_", name: "Pupil Worksheet Available", value: null});
  properties.pupilWorksheetDownloaded !== undefined && properties.pupilWorksheetDownloaded !== null ?
    eventPropertiesArray.push({id: "AbY2tLMm3", name: "Pupil Worksheet Downloaded", value: properties.pupilWorksheetDownloaded}) :
    eventPropertiesArray.push({id: "AbY2tLMm3", name: "Pupil Worksheet Downloaded", value: null});
  properties.pupilVideoPlayed !== undefined && properties.pupilVideoPlayed !== null ?
    eventPropertiesArray.push({id: "hmpQPI6sI", name: "Pupil Video Played", value: properties.pupilVideoPlayed}) :
    eventPropertiesArray.push({id: "hmpQPI6sI", name: "Pupil Video Played", value: null});
  properties.pupilVideoDurationSeconds !== undefined && properties.pupilVideoDurationSeconds !== null ?
    eventPropertiesArray.push({id: "4Ei7ce0ZP", name: "Pupil Video Duration (Seconds)", value: properties.pupilVideoDurationSeconds}) :
    eventPropertiesArray.push({id: "4Ei7ce0ZP", name: "Pupil Video Duration (Seconds)", value: null});
  properties.pupilVideoTimeEllapsedSeconds !== undefined && properties.pupilVideoTimeEllapsedSeconds !== null ?
    eventPropertiesArray.push({id: "cOQ8T6zw3", name: "Pupil Video Time Ellapsed (Seconds)", value: properties.pupilVideoTimeEllapsedSeconds}) :
    eventPropertiesArray.push({id: "cOQ8T6zw3", name: "Pupil Video Time Ellapsed (Seconds)", value: null});
  eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase});
  eventPropertiesArray.push({id: "Vv6ZE-oYpa", name: "Pupil Experience Lesson Section", value: properties.pupilExperienceLessonSection});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lucA3x3s4", "b94542715faef2edd6966a4bf6dc3962ca122f384e56fbffd9c87981f72ce048", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Section Abandoned", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lucA3x3s4", "Lesson Section Abandoned", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Section Abandoned", eventProperties, "lucA3x3s4", "b94542715faef2edd6966a4bf6dc3962ca122f384e56fbffd9c87981f72ce048");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Section Abandoned", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  PupilActivityResourceTypes,
  VideoLocation,
  ResourceFileType,
  Context,
  OnwardIntent,
  SchoolOption,
  AnalyticsUseCase,
  DownloadResourceButtonName,
  ResourceType,
  PageName,
  Phase,
  PupilExperienceLessonSection,
  FilterType,
  ShareMedium,
  SearchSource,
  NavigatedFrom,
  KeyStageTitle,
  SearchResultType,
  planALessonSelected,
  newsletterSignUpCompleted,
  classroomSelected,
  teacherHubSelected,
  developYourCurriculumSelected,
  supportYourTeamSelected,
  notificationSelected,
  aboutSelected,
  videoStarted,
  videoPaused,
  videoPlayed,
  videoFinished,
  lessonResourcesDownloaded,
  keyStageSelected,
  subjectSelected,
  unitSelected,
  lessonSelected,
  yearGroupSelected,
  webinarPageViewed,
  helpCentreSelected,
  learningThemeSelected,
  tierSelected,
  pageview,
  resourceContainerExpanded,
  curriculumMapDownloaded,
  downloadResourceButtonClicked,
  searchAttempted,
  searchResultOpened,
  searchResultsDisplayed,
  searchJourneyInitiated,
  curriculumVisualiserAccessed,
  curriculumThreadHighlighted,
  unitInformationViewed,
  onwardContentSelected,
  lessonShared,
  lessonShareStarted,
  searchRefined,
  searchResultExpanded,
  lessonCompleted,
  lessonSectionCompleted,
  lessonStarted,
  lessonSectionStarted,
  curriculumResourcesDownloaded,
  curriculumResourcesDownloadedCurriculumDocument,
  lessonSectionAbandoned,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["planALessonSelected","newsletterSignUpCompleted","classroomSelected","teacherHubSelected","developYourCurriculumSelected","supportYourTeamSelected","notificationSelected","aboutSelected","videoStarted","videoPaused","videoPlayed","videoFinished","lessonResourcesDownloaded","keyStageSelected","subjectSelected","unitSelected","lessonSelected","yearGroupSelected","webinarPageViewed","helpCentreSelected","learningThemeSelected","tierSelected","pageview","resourceContainerExpanded","curriculumMapDownloaded","downloadResourceButtonClicked","searchAttempted","searchResultOpened","searchResultsDisplayed","searchJourneyInitiated","curriculumVisualiserAccessed","curriculumThreadHighlighted","unitInformationViewed","onwardContentSelected","lessonShared","lessonShareStarted","searchRefined","searchResultExpanded","lessonCompleted","lessonSectionCompleted","lessonStarted","lessonSectionStarted","curriculumResourcesDownloaded","curriculumResourcesDownloadedCurriculumDocument","lessonSectionAbandoned"]
